import { RouteProps, RouteComponentProps } from "react-router";
import lazy, { LoadableComponent } from "@loadable/component";

interface AppRoute extends RouteProps {
  name: string;
  path: string;
  component: LoadableComponent<RouteComponentProps>;
  isPublic?: boolean;
}

const AuthenticationPage = lazy(() => import("Authentication"));
const OrganizationsPage = lazy(() => import("Organization"));
const BillingPage = lazy(() => import("Billing"));
const UserProfilePage = lazy(() => import("UserProfile/UserProfilePage"));
const UsersPage = lazy(() => import("Users"));

const routes: AppRoute[] = [
  {
    name: "Organizations",
    path: "/",
    component: OrganizationsPage,
    exact: true
  },
  {
    name: "Authentication",
    path: "/authentication/:method?",
    component: AuthenticationPage,
    isPublic: true
  },
  { name: "Billing", path: "/billing/:method?", component: BillingPage },
  { name: "Profile", path: "/profile", component: UserProfilePage },
  { name: "Users", path: "/users", component: UsersPage }
];

export default routes;
