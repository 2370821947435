import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { AppSession } from "@ebs-platform/common";
import SessionGuard from "./SessionGuard";
import "@ebs-platform/common/dist/styles.css";
import "@ebs-platform/components/lib/index.scss";
import PublicRoutes from "libs/router/PublicRoutes";
import { AccountProvider } from "contexts";
import "./App.css";

/**
 * This is the "entry" component of this application.
 * Its job is to render routes wrapped by session context.
 *
 * This is the comonent mounted in the #root element.
 */
const App: React.FC = () => (
  <BrowserRouter>
    <AppSession>
      <AccountProvider>
        <Route>
          <PublicRoutes />
          <SessionGuard />
        </Route>
      </AccountProvider>
    </AppSession>
  </BrowserRouter>
);

export default App;
