import React, { createContext, useContext, useEffect, useState } from "react";

import { EditUserPayload, User } from "libs/api/users";
import api from "libs/api";
import { message } from "antd";
import { AppSessionContext } from "@ebs-platform/common";

export interface AccountContextValue {
  user: User | null;
  editAdminAccount: (data: EditUserPayload) => Promise<void>;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const initialValue: AccountContextValue = {
  user: null,
  editAdminAccount: () => Promise.resolve(),
  setUser: () => null
};

export const AccountContext = createContext<AccountContextValue>(initialValue);

export const AccountProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const { session } = useContext(AppSessionContext);

  useEffect(() => {
    session.isSessionValid &&
      api.users
        .adminAccounts()
        .then((res) => {
          setUser(res.user);
        })
        .catch((e) =>
          message.error((e as Error).message || "Something is wrong")
        );
  }, [session.isSessionValid]);

  const editAdminAccount = async (data: EditUserPayload): Promise<void> => {
    try {
      const user = await api.users.editAdminAccounts(data);
      setUser(user);
      message.success("Profile updated");
    } catch (error) {
      message.error((error as Error).message);
    }
  };

  const providerValue: AccountContextValue = {
    user,
    editAdminAccount,
    setUser
  };

  return (
    <AccountContext.Provider value={providerValue}>
      {children}
    </AccountContext.Provider>
  );
};
