import fetch from "cross-fetch";
import { ApiConfig } from "@ebs-platform/common/dist/api";
import queryString from "query-string";
import { FieldsValidationError, NonFieldsError } from "../utils";

/**
 * This interfaces defines the data required
 * for registration.
 */
export interface RegisterRequestData {
  email: string;
  company_name: string;
  service_domain: string;
  password: string;
  confirm_password: string;
}

export interface UsersResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: User[];
}

/**
 * This interface defines the data from
 * a succesfull register.
 */
export interface RegisterResponseData {
  account_id: number;
  email: string;
  first_name: string;
  last_name: string;
  organization_id: number;
  access: string;
  refresh: string;
}

export interface UserApplication {
  id: number;
  title: string;
  description?: string;
  type: string;
}

export interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  photo: string;
  last_login: string;
  position: string;
  sso_id: string;
  teams: string;
  language: string;
  application_role: string;
  archived: boolean;
}

export interface EditUserPayload {
  first_name?: string;
  last_name?: string;
  phone?: string;
  photo?: string;
  position?: string;
}

export interface Billing {
  id?: number;
  name?: string;
  billing_first_name?: string;
  billing_last_name?: string;
  billing_phone?: string;
  billing_email?: string;
  billing_address1?: string;
  billing_address2?: string;
  billing_country?: number;
  billing_city?: string;
  billing_zip?: string;
}

/**
 * This holds access rights of the current user
 * to a specific organization account.
 */
export interface AccountAccess {
  id: number;
  role: "admin" | "user";
  account: { id: number; name: string };
}

export interface AdminAccountsResponse {
  user: User;
  account_access: AccountAccess[];
}

export interface PaginationParams {
  page: number;
  pageSize: number;
}

interface AllUsersParams extends PaginationParams {
  archived?: string;
  search?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default (config: ApiConfig) => ({
  /**
   * This will attempt to register with the provided data.
   *
   * Note: the returned error might be of different types.
   *
   * @returns - a promise with an array with exactly 2 values,
   * the first is the success response, the second is the generated error.
   */
  register: async (
    data: RegisterRequestData
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<[RegisterResponseData | null, any]> => {
    let response: RegisterResponseData | null = null;
    let err = null;

    try {
      const res = await fetch(`${config.baseUrl}/users/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      const resData = await res.json();

      if (res.ok) {
        response = resData;
      } else if (res.status === 400) {
        err = Array.isArray(resData.non_field_errors)
          ? new NonFieldsError(resData.non_field_errors[0])
          : new FieldsValidationError(resData, data);
      } else {
        err = resData;
      }
    } catch (e) {
      err = e;
    }

    return [response, err];
  },

  /**
   * Fetches accounts access information for an admin user.
   */
  adminAccounts: async (): Promise<AdminAccountsResponse> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const res = await fetch(`${config.baseUrl}/users/login/admin/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${config.tokens.access}`
      }
    });
    const data = await res.json();

    if (res.ok) {
      return data;
    } else if (res.status === 403) {
      throw new Error(data?.detail);
    } else {
      throw new Error("Unexpected response.");
    }
  },

  /**
   * Edit user account.
   */
  editAdminAccounts: async (body: EditUserPayload): Promise<User> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const res = await fetch(`${config.baseUrl}/users/edit/`, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Token ${config.tokens.access}`,
        "Content-Type": "application/json"
      }
    });
    const data = await res.json();

    if (res.ok) {
      return data;
    } else {
      throw new Error("Unexpected response.");
    }
  },

  /**
   * Edit user billing.
   */
  editBilling: async (body: Billing): Promise<Billing> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const res = await fetch(`${config.baseUrl}/users/billing-edit/`, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Token ${config.tokens.access}`,
        "Content-Type": "application/json"
      }
    });
    const data = await res.json();

    if (res.ok) {
      return data;
    } else {
      throw new Error("Unexpected response.");
    }
  },

  /**
   * Fetches all applications for an admin user.
   */
  userApplications: async (): Promise<UserApplication[]> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const res = await fetch(`http://www.mocky.io/v2/5d9da8aa3200007a003297ed`, {
      method: "GET"
    });
    const data = await res.json();

    if (res.ok) {
      return data;
    } else {
      throw new Error("Unexpected response.");
    }
  },

  accountDetail: async (id: number): Promise<Billing> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const res = await fetch(`${config.baseUrl}/accounts/${id}/`, {
      method: "GET",
      headers: { Authorization: `Token ${config.tokens?.access}` }
    });
    const data = await res.json();

    if (res.ok) {
      return data;
    } else {
      throw new Error("Unexpected response.");
    }
  },

  all: async ({
    page,
    pageSize,
    archived,
    search
  }: AllUsersParams): Promise<UsersResponse> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const fullURL = queryString.stringifyUrl(
      {
        url: `${config.baseUrl}/users/all/`,
        query: { page, page_size: pageSize, archived, search } as {}
      },
      { skipNull: true }
    );

    const res = await fetch(fullURL, {
      method: "GET",
      headers: { Authorization: `Token ${config.tokens?.access}` }
    });

    const data = await res.json();

    if (res.ok) {
      return data;
    } else {
      throw new Error("Unexpected response.");
    }
  },

  archiveById: async (id: number): Promise<void> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const res = await fetch(`${config.baseUrl}/users/archive/${id}/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${config.tokens?.access}`
      }
    });

    if (!res.ok) {
      throw new Error("Unexpected response.");
    }
  }
});
