import { useState } from "react";
import { CURRENT_PAGE, PAGE_SIZE } from "app-constant";

interface UsePagination {
  current: number;
  pageSize: number;
  changeCurrentPage: (currentPage: number) => void;
  changeSizePage: (size: number) => void;
}

const usePagination = (): UsePagination => {
  const [current, setCurrent] = useState(CURRENT_PAGE);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const changeCurrentPage = (current: number): void => {
    setCurrent(current);
  };

  const changeSizePage = (size: number): void => {
    setPageSize(size);
  };

  return {
    current,
    pageSize,
    changeCurrentPage,
    changeSizePage
  };
};

export default usePagination;
